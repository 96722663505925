<template>
	<login-fields
		:custom_login='false'
	/>
</template>
<script>
import LoginFields from '@/components/login/LoginFields.vue'
export default ({
	name: 'Login_2FA',
	meta: {
		title: 'Alert Cascade - Verification'
	},
	components: {
		LoginFields
	},
})
</script>
